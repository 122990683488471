export const COOKIE_PREFIX =
  process.env.BASE_URL === "https://aragro.com" ? "_beta" : "_staging";

export function getCookie(name) {
  try {
    const value = "; " + document.cookie;
    const parts = value.split("; " + name + "=");
    if (parts.length == 2) {
      return parts.pop().split(";").shift();
    }
  } catch (err) {
    console.error("Could not get cookie:", err);
    return null;
  }
}

export function setCookie(name, value, attributes = {}) {
  const hostname = window.location.hostname;
  // Get root domain for production (without leading dot)
  const domain =
    hostname === "localhost"
      ? hostname
      : hostname.split(".").slice(-2).join(".");

  attributes = {
    domain,
    path: "/",
    "max-age": 86400000,
    SameSite: "Lax",
    ...attributes,
  };

  if (attributes.expires instanceof Date) {
    attributes.expires = attributes.expires.toUTCString();
  }

  let updatedCookie =
    encodeURIComponent(name) + "=" + encodeURIComponent(value);

  for (let attributeKey in attributes) {
    updatedCookie += "; " + attributeKey;
    let attributeValue = attributes[attributeKey];
    if (attributeValue !== true) {
      updatedCookie += "=" + attributeValue;
    }
  }

  document.cookie = updatedCookie;
}

// Helper function to get user's IP information
export async function getUserIpInfo() {
  try {
    // Check if IP info is already cached in localStorage
    const cachedData = localStorage.getItem("ipInfo");

    if (cachedData) {
      console.debug("Using cached IP info");

      const { data, timestamp } = JSON.parse(cachedData);
      const cacheAge = Date.now() - timestamp;

      // Cache valid for 1 hour (3600000 ms)
      if (cacheAge < 3600000) {
        // Set country_code cookie if we have valid cached data
        if (data.countryCode) {
          setCookie("country_code", data.countryCode);
        }
        return data;
      }
    }

    // Fetch fresh data if no cache or cache expired
    const response = await fetch("https://ipapi.co/json/", {
      signal: AbortSignal.timeout(3000),
    });
    const apiData = await response.json();

    const data = {
      ip: apiData.ip,
      countryName: apiData.country_name,
      countryCode: apiData.country_code,
    };

    // Cache the data with timestamp
    localStorage.setItem(
      "ipInfo",
      JSON.stringify({
        data,
        timestamp: Date.now(),
      })
    );

    // Set country_code cookie
    if (data.countryCode) {
      setCookie("country_code", data.countryCode);
    }

    return data;
  } catch (err) {
    return null;
  }
}
